import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '#app/utils/misc.tsx';

const labelVariants = cva(
	'font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
	{
		variants: {
			labelStyle: {
				floating:
					'absolute duration-300 top-3 -z-1 origin-0 text-gray-500 left-5',
			},
		},
	},
);

const Label = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
		VariantProps<typeof labelVariants>
>(({ className, labelStyle, ...props }, ref) => (
	<>
		<LabelPrimitive.Root
			ref={ref}
			className={cn(labelVariants({ labelStyle: labelStyle }), className)}
			{...props}
		/>
	</>
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
